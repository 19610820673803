<template>
<v-card class="display-block" flat>
    <h3>Update Inventory</h3>
    <v-card-text>
        <v-alert v-if="msg" :type="msgType">
            <ul v-for="item in msg" :key="item.errors">
                <li>{{item}}</li>
            </ul>
        </v-alert>
        <v-form ref="inventoryForm" lazy-validation>
            <v-layout wrap>
                <v-flex xs12 sm6>
                    <v-autocomplete class="pa-1" v-model="selectedProduct" clearable label="Product*" item-text="displayName" item-value="_id" :rules="fieldRequired" :items="products" return-object></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 text-center>
                    <v-text-field class="pa-1" :label="'New Stock*'" type="number" :rules="noDecimal" v-model.number="productInventory.stock" required></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 text-center>
                    <a class="text-center" v-if="!productInventory.updateReservedStock" @click="toggleReserveStockUpdate()">Change Reserve Stock</a>
                    <v-text-field class="pa-1" v-if="productInventory.updateReservedStock" v-model.number="productInventory.reserveStock" clearable @click:clear="toggleReserveStockUpdate()"></v-text-field>
                </v-flex>
                <v-flex v-if="id==0" text-right>
                    <v-btn class="ma-4" :style="theme" @click="addToInventory()" outlined>Add</v-btn>
                </v-flex>
            </v-layout>
            <v-card v-if="id==0">
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Product</td>
                                    <td class="text-right">New Stock</td>
                                    <td class="text-right">Reserve Stock</td>
                                    <td class="text-right">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in inventory.products" :key="index">
                                    <td>{{item.displayName}}</td>
                                    <td class="text-right">{{item.stock}}</td>
                                    <td class="text-right"><label v-if="item.updateReservedStock">{{item.reserveStock}}</label></td>
                                    <td class="text-right">
                                        <v-icon @click="removeItem(index)">mdi-close</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
            <v-flex v-if="id!=0">
                <v-text-field label="Reserve Stock" type="number" v-model="productInventory.reserveStock"></v-text-field>
            </v-flex>
            <v-alert v-if="msg" :type="msgType">
                <ul v-for="item in msg" :key="item.errors">
                    <li>{{item}}</li>
                </ul>
            </v-alert>
            <v-flex class="mt-4" text-right>
                <v-btn class="mx-1" :style="theme" @click="submit()">Save</v-btn>
                <v-btn class="mx-1" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
            </v-flex>
        </v-form>
    </v-card-text>
</v-card>
</template>

<script>
import appConstants from '@/utils/appConstants'
import axios from 'axios'
export default {
    data() {
        return {
            productInventory: this.defaultInventoryProductModel(),
            products: [],
            selectedProduct: undefined,
            id: 0,
            stockRules: [
                v => !!v || 'Stock is required',
            ],
            show: false,
            inventory: {
                products: []
            },
            noDecimal: [v => !!v || 'Field is required', v => /^[-]?[0-9]+$/.test(v) || 'Decimal Value is not allowed']
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.products = await this.getItem(appConstants.PRODUCTS_API + "/refList")
            if (this.id != 0) {
                this.productInventory = await this.getItem(appConstants.INVENTORY_API + "/" + this.id)
                this.show = true
            }
        },
        submit() {
            if (this.id == 0) {
                this.saveItem()
            } else {
                this.updateItem()
            }
        },
        async saveItem() {
            try {
                this.inventory.vendorId = this.getUserProfile().vendorId
                await this.postItem(appConstants.INVENTORY_API, this.inventory)
                this.$router.push({
                    name: "Inventory"
                })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.errors
            }
        },
        async updateItem() {
            try {
                await this.putItem(appConstants.INVENTORY_API + "/" + this.id, this.productInventory)
                this.$router.push({
                    name: "Inventory"
                })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.errors
            }
        },
        addToInventory() {
            if (this.$refs.inventoryForm.validate()) {
                let index = this.inventory.products.findIndex(rec => rec.productId == this.selectedProduct._id)
                if (index > -1) {
                    let rec = this.inventory.products[index]
                    rec.stock += this.productInventory.stock
                    this.inventory.products.splice(index, 1, rec)
                } else {
                    this.assignSelectedProduct(this.selectedProduct)
                    this.inventory.products.push(this.productInventory)
                }
                this.productInventory = this.defaultInventoryProductModel()
            }
        },
        assignSelectedProduct(item){
            this.productInventory.productId = item._id
            this.productInventory.displayName = item.displayName
            this.productInventory.code = item.value
        },
        removeItem(index) {
            this.inventory.products.splice(index, 1)
        },
        toggleReserveStockUpdate() {
            this.productInventory.updateReservedStock = !this.productInventory.updateReservedStock
        },
        defaultInventoryProductModel() {
            this.selectedProduct = undefined
            return {
                stock: 0,
                updateReservedStock: false,
                reserveStock: 0
            }
        }
    }
}
</script>
